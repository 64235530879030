<template>
    <div class="home">

        <header class="page-header">
            <div class="page-header-content">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <img class="img-fluid" src="../assets/img/logo.svg" style="width: 25rem;"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="svg-border-rounded text-light">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none"
                     fill="currentColor">
                    <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                </svg>
            </div>
        </header>


        <!-- Ab-Hof Verkauf -->
        <section class="bg-light py-10">
            <div class="container">
                <div class="row text-center">
                    <div class="col-lg-6">
                        <h2 class="mb-4 underline">Ab-Hof Verkauf</h2>
                        <p class="mb-4">Besuchen Sie unseren Ab-Hof Verkauf!</p>
                        <!--
                        <p class="specialOpeningMessage">Als landwirtschaftlicher Gartenbaubetrieb haben wir
                            <span class="specialOpeningMessageEmphasize">auch im Lockdown</span> für Sie geöffnet!</p>
                        -->
                        <aktuelle-oeffnungszeiten />
                    </div>
                    <div class="col-lg-6">
                        <img class="img-fluid rounded" src="../assets/img/blumengeschaeft.jpg">
                    </div>
                </div>
            </div>
            <div class="svg-border-rounded text-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none"
                     fill="CurrentColor">
                    <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                </svg>
            </div>
        </section>


        <!-- Gärtnerei -->
        <section class="bg-white py-10">
            <div class="container">
                <div class="row text-center">
                    <div class="col-lg-12">
                        <h2 class="mb-5 underline">Die Gärtnerei am Berg</h2>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-lg-6 col-12">
                        <figure class="figure mb-4">
                            <img class="img-fluid rounded mb-2" src="../assets/img/gaertnerei.jpg">
                            <figcaption class="figure-caption"></figcaption>
                        </figure>
                    </div>
                    <div class="col-lg-6 col-12">
                        <p class="mb-4 text-lg-left text-paragraph">Wir betreiben unseren landwirtschaftlichen
                            Gartenbaubetrieb im niederösterreichischen Großweikersdorf nun bereits in 4. Generation.
                            Unsere Gewächshäuser befinden sich außerhalb des Ortes auf einer leichten Anhöhe – daher der
                            Name „Die Gärtnerei am Berg“.</p>
                        <p class="mb-4 text-lg-left text-paragraph">Den jetzigen Betrieb haben Michael und Roswitha
                            Höller vor über 35 Jahren aufgebaut und seitdem ist er stetig gewachsen. </p>
                    </div>
                </div>
            </div>
            <div class="svg-border-rounded text-light">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none"
                     fill="CurrentColor">
                    <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                </svg>
            </div>
        </section>


        <!-- Unsere Mission -->
        <section class="bg-light py-10">
            <div class="container">
                <div class="row text-center">
                    <div class="col-lg-12">
                        <h2 class="mb-5 underline">Unsere Mission</h2>
                    </div>
                    <div class="col-lg-6 col-12">
                        <p class="mb-4 text-lg-center text-paragraph">Wir legen großen Wert auf eine ökologische
                            Produktion, Regionalität und eine hohe Produktqualität. Bei unseren Planungen und
                            Entscheidungen sowie unserer Produktion ist der Nachhaltigkeitsaspekt stets ein fester
                            Bestandteil. Unser gesamtes Sortiment an Gemüse- und Gewürzpflanzen wird daher in
                            zertifizierter BIO-Qualität produziert. Auch in der Produktion von Blumen und Zierpflanzen
                            haben wir uns schon vor einiger Zeit entschlossen, auf Pestizide bestmöglich zu verzichten
                            und stattdessen schon im Vorfeld natürliche Mittel zur Pflanzenstärkung einzusetzen.</p>
                    </div>
                    <div class="col-lg-6 col-12">
                        <figure class="figure mb-4">
                            <img class="img-fluid rounded mb-2" src="../assets/img/team.jpg">
                            <figcaption class="figure-caption">Familie Höller: Michael jun. und Kerstin, Roswitha und
                                Michael sen.
                            </figcaption>
                        </figure>
                    </div>
                </div>
            </div>
            <div class="svg-border-rounded text-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none"
                     fill="CurrentColor">
                    <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                </svg>
            </div>
        </section>


        <!-- Unsere Produkte -->
        <section class="bg-white py-10">
            <div class="container sortiment">
                <div class="col-lg-12">
                    <h2 class="mb-5 underline">Unsere Produkte</h2>
                </div>
                <p class="mb-4 text-lg-left text-paragraph">Je nach Jahreszeit blühen unsere Produktionen in
                    unterschiedlicher Farbenpracht und Vielfalt: Im Frühjahr sind unsere Gewächshäuser gefüllt mit einer
                    bunten Auswahl an Beet- und Balkonpflanzen sowie einem reichhaltigen Sortiment an BIO Gewürz- und
                    Gemüsejungpflanzen. Im Sommer produzieren wir darüber hinaus etliche Stauden, gefolgt von Violen und
                    diversen Chrysanthemensorten im Herbst. Im Winter produzieren wir Weihnachtssterne in allen
                    möglichen Farben und Schattierungen. Wir beliefern hauptsächlich Kunden in und rund um
                    Niederösterreich. All unsere Produktionen können auch in unserem Ab-Hof Verkauf erworben werden.</p>

                <div class="row">
                    <div class="col-xs-1 col-md-4">
                        <div class="card">
                            <img src="../assets/img/sortiment/1-zeitiges_fruehjahr/zeitig_001.jpg" class="card-img-top"
                                 alt=""/>
                            <div class="card-body">
                                <h5 class="card-title">zeitiges Frühjahr</h5>
                                <div class="card-text">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">Blumen</li>
                                        <li class="list-group-item">Salate und Kohlgemüse</li>
                                        <li class="list-group-item">Kräuter</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-1 col-md-4">
                        <div class="card">
                            <img src="../assets/img/sortiment/2-fruehjahr/fruehjahr_001.jpg" class="card-img-top"
                                 alt="..."/>
                            <p class="card-body">
                            <h5 class="card-title">Frühjahr</h5>
                            <div class="card-text">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">Balkonblumen</li>
                                    <li class="list-group-item">Beetpflanzen</li>
                                    <li class="list-group-item">Salate und Kohlgemüse</li>
                                    <li class="list-group-item">Kräuter</li>
                                    <li class="list-group-item">Früchtgemüse</li>
                                    <li class="list-group-item">Beeren und Erdbeeren</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-xs-1 col-md-4">
                        <div class="card">
                            <img src="../assets/img/sortiment/3-sommer/sommer_001.jpg" class="card-img-top" alt="..."/>
                            <div class="card-body">
                                <h5 class="card-title">Sommer</h5>
                                <div class="card-text">
                                    <ul class="list-group list-group-flush">
                                        <li class="list-group-item">Stauden und Gräser</li>
                                        <li class="list-group-item">Salate und Kohlgemüse</li>
                                        <li class="list-group-item">Kräuter</li>
                                        <li class="list-group-item">Beeren</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">

                        <div class="col-xs-1 col-md-4 offset-md-1">
                            <div class="card">
                                <img src="../assets/img/sortiment/4-herbstsortiment/herbst_001.jpg" class="card-img-top"
                                     alt="..."/>
                                <div class="card-body">
                                    <h5 class="card-title">Herbst</h5>
                                    <div class="card-text">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item">Multiflora</li>
                                            <li class="list-group-item">Strukturpflanzen</li>
                                            <li class="list-group-item">Beetpflanzen</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xs-1 col-md-4 offset-md-2">
                            <div class="card">
                                <img src="../assets/img/sortiment/5-winter/winter_001.jpg" class="card-img-top"
                                     alt="..."/>
                                <div class="card-body">
                                    <h5 class="card-title">Winter</h5>
                                    <div class="card-text">
                                        <ul class="list-group list-group-flush">
                                            <li class="list-group-item">Weihnachtssterne</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <!--
                <div class="row" data-toggle="modal" data-target="#blumen">
                  <div class="col-lg-2 col-md-4 col-6 d-block mb-4 pr-3 pl-3">
                    <a href="#">
                      <img class="img-fluid rounded" src="../assets/img/blumen/blume1.jpg" data-target="#galerie" data-slide-to="0">
                    </a>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 d-block mb-4 pr-3 pl-3">
                    <a href="#">
                      <img class="img-fluid rounded" src="../assets/img/blumen/blume2.jpg" data-target="#galerie" data-slide-to="1">
                    </a>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 d-block mb-4 pr-3 pl-3">
                    <a href="#">
                      <img class="img-fluid rounded" src="../assets/img/blumen/blume3.jpg" data-target="#galerie" data-slide-to="2">
                    </a>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 d-block mb-4 pr-3 pl-3">
                    <a href="#">
                      <img class="img-fluid rounded" src="../assets/img/blumen/blume4.jpg" data-target="#galerie" data-slide-to="3">
                    </a>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 d-block mb-4 pr-3 pl-3">
                    <a href="#">
                      <img class="img-fluid rounded" src="../assets/img/blumen/blume5.jpg" data-target="#galerie" data-slide-to="3">
                    </a>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 d-block mb-4 pr-3 pl-3">
                    <a href="#">
                      <img class="img-fluid rounded" src="../assets/img/blumen/blume6.jpg" data-target="#galerie" data-slide-to="3">
                    </a>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 d-block mb-4 pr-3 pl-3">
                    <a href="#">
                      <img class="img-fluid rounded" src="../assets/img/blumen/blume7.jpg" data-target="#galerie" data-slide-to="3">
                    </a>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 d-block mb-4 pr-3 pl-3">
                    <a href="#">
                      <img class="img-fluid rounded" src="../assets/img/blumen/blume8.jpg" data-target="#galerie" data-slide-to="3">
                    </a>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 d-block mb-4 pr-3 pl-3">
                    <a href="#">
                      <img class="img-fluid rounded" src="../assets/img/blumen/blume9.jpg" data-target="#galerie" data-slide-to="3">
                    </a>
                  </div>
                  <div class="col-lg-2 col-md-4 col-6 d-block mb-4 pr-3 pl-3">
                    <a href="#">
                      <img class="img-fluid rounded" src="../assets/img/blumen/blume10.jpg" data-target="#galerie" data-slide-to="3">
                    </a>
                  </div>
                </div>
                -->

                <!--
                <div class="modal fade" id="blumen" tabindex="-1" role="dialog" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div id="galerie" class="carousel slide" data-ride="carousel">
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <img class="d-block w-100" src="../assets/img/blumen/blume1.jpg" alt="First slide">
                            </div>
                            <div class="carousel-item">
                              <img class="d-block w-100" src="../assets/img/blumen/blume2.jpg" alt="Second slide">
                            </div>
                            <div class="carousel-item">
                              <img class="d-block w-100" src="../assets/img/blumen/blume3.jpg" alt="Third slide">
                            </div>
                            <div class="carousel-item">
                              <img class="d-block w-100" src="../assets/img/blumen/blume4.jpg" alt="Fourth slide">
                            </div>
                            <div class="carousel-item">
                              <img class="d-block w-100" src="../assets/img/blumen/blume5.jpg" alt="Fourth slide">
                            </div>
                            <div class="carousel-item">
                              <img class="d-block w-100" src="../assets/img/blumen/blume6.jpg" alt="Fourth slide">
                            </div>
                            <div class="carousel-item">
                              <img class="d-block w-100" src="../assets/img/blumen/blume7.jpg" alt="Fourth slide">
                            </div>
                            <div class="carousel-item">
                              <img class="d-block w-100" src="../assets/img/blumen/blume8.jpg" alt="Fourth slide">
                            </div>
                            <div class="carousel-item">
                              <img class="d-block w-100" src="../assets/img/blumen/blume9.jpg" alt="Fourth slide">
                            </div>
                            <div class="carousel-item">
                              <img class="d-block w-100" src="../assets/img/blumen/blume10.jpg" alt="Fourth slide">
                            </div>
                          </div>
                          <a class="carousel-control-prev" href="#galerie" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a class="carousel-control-next" href="#galerie" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                -->

            </div>

            <div class="svg-border-rounded text-light">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none"
                     fill="CurrentColor">
                    <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
                </svg>
            </div>
        </section>

      <section class="bg-light py-10">
        <div class="container">
          <div class="row text-center">
            <div class="col-lg-12">
              <h2 class="mb-5 underline">Unser Team</h2>
            </div>
            <div class="col-lg-6 col-12">
              <p class="mb-4 text-lg-center text-paragraph">
                In unserem Familienbetrieb stehen nicht nur die Pflanzen im Vordergrund, sondern gleichermaßen die Menschen, die dahinterstehen! Wir sind davon überzeugt, dass der Erfolg unserer Gärtnerei nur durch ein gutes Miteinander erreicht werden kann. Viele Hände und ein gutes Zusammenspiel aller Mitarbeiter sind notwendig, um unseren Kunden gesunde Pflanzen in hoher Qualität bieten zu können.
                <!--                <a href="/jobs">Hier geht's zu unseren freien Stellen</a>-->
              </p>
              <p class="mb-4 text-lg-center text-paragraph">
                Für unser Team suchen wir derzeit Verstärkung – nähere Infos findet ihr unter folgendem Link:
                <router-link to="/jobs" class="btn-primary btn rounded-pill px-4 ml-lg-4"><i
                    class="fas fa-arrow-right ml-1"></i> <span class="font-weight-bold">freien Stellen</span></router-link>
              </p>
            </div>
            <div class="col-lg-6 col-12">
              <figure class="figure mb-4">
                <img class="img-fluid rounded mb-2" src="https://gaertnerei-am-berg.at/_data/Team.jpg">
                <figcaption class="figure-caption">
                  ...
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
        <div class="svg-border-rounded text-white">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none"
               fill="CurrentColor">
            <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path>
          </svg>
        </div>
      </section>

        <!--
        <section class="bg-light py-10">
          <div class="svg-border-rounded text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="CurrentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
          </div>
        </section>
        -->
    </div>
</template>

<script>
    // @ is an alias to /src
    import AktuelleOeffnungszeiten from "../components/AktuelleOeffnungszeiten";

    export default {
        name: 'Home',
        components: {
            AktuelleOeffnungszeiten
        }
    }
</script>

<style scoped>
    .specialOpeningMessage {
        font-size: 22px;
        font-weight: bold;
        color: #A3CB38;
        text-shadow: 0.3px 0.3px black;
    }

    .specialOpeningMessageEmphasize {
        border-bottom: 4px solid #C43D23;
        display: inline-block;
        line-height: 1.5;
    }

    .text-paragraph {
        font-size: 19px;
    }

    .sortiment .card-title {
        font-size: 28px;
    }

    .sortiment .card-deck {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .sortiment .card {
        margin-top: 20px;
        margin-bottom: 20px;
    }

</style>
