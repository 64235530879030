<template>
    <div class="hello" v-if="currentOpeningHours !== undefined">
        <div style="margin-top: 30px; margin-bottom: 30px;">
            <div class="current-opening-hours" v-if="true">
                <div>
                    <span>heute </span>
                    <span class="openString"
                          :class="currentStatement.status === 1 ? 'open' : (currentStatement.status === 0 ? 'closed' : '')">{{currentStatement.openString}}</span>
                </div>
                <div>
                    <span class="special-today">{{currentStatement.special}}</span>
                </div>
                <div>
                    <span v-if="currentStatement.status === 0" class="closedMessage">{{currentStatement.message}}</span>
                    <span v-if="currentStatement.status === 1" class="openMessage">{{getFormattedOpeningHoursForDate(now)}}</span>

                </div>
            </div>

            <div class="current-opening-hours" v-else-if="true">
                <div>
                    <span>derzeit </span>
                    <span class="openString"
                          :class="currentStatement.status === 1 ? 'open' : (currentStatement.status === 0 ? 'closed' : '')">{{currentStatement.openString}}</span>
                </div>
                <div>
                    <span class="openMessage">{{currentStatement.message}}</span>
                </div>
            </div>
            <div class="current-opening-hours" v-else>
                <div>
                    derzeit:
                </div>
                <div>
                    <span class="openString"
                          :class="currentStatement.status === 1 ? 'open' : (currentStatement.status === 0 ? 'closed' : '')">{{currentStatement.openString}}</span>
                    <span> - </span>
                    <span class="openMessage">{{currentStatement.message}}</span>
                </div>
            </div>

            <button class="btn showRemainingWeekButton"
                    :class="showRemainingWeek ? 'btn-success' : 'btn-outline-success'" @click="toggleShowRemainingWeek">
                die restliche Woche
            </button>
            <table v-if="showRemainingWeek" class="table text-left">
                <tbody>
                <tr v-for="i in 7" :key="i">
                    <th class="day"><span :class="(getDayOfWeek(now) + (i+0)) % 7 === 0 ? 'sunday' : ''">{{weekdayString(getDayOfWeek(now) + (i+0))}}</span>
                    </th>
                    <td>{{getFormattedOpeningHoursForDatePlus(now, (i+0))}}</td>
                    <td><span class="special-in-week">{{special(now, (i+0))}}</span></td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>

</template>

<script>
    export default {
        name: 'AktuelleOeffnungszeiten',
        props: {
            now2: Date
        },
        data: function () {
            return {

                currentOpeningHours: null,
                nextWeekOpeningHours: null,
                showRemainingWeek: false,

                specialHours: [],
                openingHours: [],
                now: new Date(),
                w: this.getWeekNumber(new Date()),
                dayOfWeek: this.getDayOfWeek(new Date()),
                interval: null
            }
        },
        methods: {
            newDate(day, month, year) {
                return new Date(year, month - 1, day);
            },
            toggleShowRemainingWeek() {
                this.showRemainingWeek = !this.showRemainingWeek;
            },
            weekdayString(day) {
                switch (day % 7) {
                    case 1:
                        return "Montag";
                    case 2:
                        return "Dienstag";
                    case 3:
                        return "Mittwoch";
                    case 4:
                        return "Donnerstag";
                    case 5:
                        return "Freitag";
                    case 6:
                        return "Samstag";
                    case 0:
                    case 7:
                        return "Sonntag";
                    default:
                        return "?";
                }
            },
            updateCurrentTime() {
                this.now = new Date();
                this.w = this.getWeekNumber(this.now);
                this.dayOfWeek = this.getDayOfWeek(this.now);
            },
            getWeekNumber(date) {
                var d = new Date();
                d.setDate(date.getDate());
                var dayNum = d.getUTCDay() || 7;
                d.setUTCDate(d.getUTCDate() + 4 - dayNum);
                var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
                let result = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
                return result;
            },
            getYear(d) {
                return d.getFullYear();
            },
            getDayOfWeek(d) {
                return d.getDay() === 0 ? 7 : d.getDay();
            },
            findCurrentOpeningHours() {
                return this.findWeeklyOpeningHours(this.w)
            },
            findWeeklyOpeningHours(week) {
                return this.openingHours.find((h) => h.fromWeek <= week && week <= h.toWeek)
            },
            findOpeningHours(w, d) {
                return this.openingHours
                    .find((h) => h.fromWeek <= w && w <= h.toWeek)
                    .entries.find((h2) => h2.fromDay <= d && d <= h2.toDay)
            },
            formatDailyOpeningHours(entries) {
                if (entries.length === 0) {
                    return "geschlossen";
                } else {
                    return entries.map(e => this.formatDuration(e)).join(" und ");
                }
            },
            formatOpeningHoursDays(o) {
                if (o.fromDay === o.toDay) {
                    return this.weekdayString(o.fromDay);
                } else {
                    return this.weekdayString(o.fromDay) + ' bis ' + this.weekdayString(o.toDay);
                }
            },
            formatDuration(entry) {
                return this.formatHourMin(entry.s) + "-" + this.formatHourMin(entry.e);
            },
            formatHourMin(n) {
                let h = Math.floor(n);
                return h + ":" + this.formatTwoDigits(Math.floor((n - h) * 60));
            },
            formatTwoDigits(n) {
                if (n < 10) {
                    return "0" + n;
                } else {
                    return "" + n;
                }
            },
            formatDateShort(d) {
                var options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
                return d.toLocaleDateString("de-DE", options);
            },
            getOpenHoursForDate(d) {
                let week = this.getWeekNumber(d);
                let day = this.getDayOfWeek(d);

                let d00 = new Date();
                d00.setTime(d.getTime());
                d00.setHours(0, 0, 0, 0);

                let special = this.specialHours.find(s => {
                    return s.date.getTime() === d00.getTime()
                });
                let openingHours = this.findOpeningHours(week, day);

                if (special != null) {
                    return special;
                } else {
                    return openingHours;
                }
            },
            special(d, plus) {
                let d2 = new Date();
                d2.setDate(d.getDate() + plus);
                let hours = this.getOpenHoursForDate(d2);
                return hours.text;
            },
            getFormattedOpeningHoursForDatePlus(d, plus) {
                let d2 = new Date();
                d2.setDate(d.getDate() + plus);
                let hours = this.getOpenHoursForDate(d2);
                return this.formatDailyOpeningHours(hours.open);
            },
            getFormattedOpeningHoursForDate(d) {
                let hours = this.getOpenHoursForDate(d);
                return this.formatDailyOpeningHours(hours.open);
            },
            generateStatement(datetime) {
                let now = new Date();
                if (datetime !== null) {
                    now.setTime(datetime.getTime());
                }
                let hours = this.getOpenHoursForDate(now);

                let h = now.getHours();
                let min = now.getMinutes();

                let time = h + (min / 60);

                let open = hours.open.find((h) => h.s <= time && time <= h.e);

                if (open !== undefined) {
                    let timeRemaining = open.e - time;
                    if (timeRemaining < 1) {
                        // hurry up!
                        return {
                            status: 1,
                            openString: "GEÖFFNET",
                            message: "schließt in " + Math.floor(timeRemaining * 60) + " min (" + this.formatHourMin(open.e) + " Uhr)",
                            special: hours.text
                        }
                    } else {
                        // open
                        return {
                            status: 1,
                            openString: "GEÖFFNET",
                            message: "schließt um " + this.formatHourMin(open.e) + " Uhr",
                            special: hours.text
                        }
                    }
                } else {
                    let opensToday = hours.open.find((h) => h.s > time);
                    if (opensToday !== undefined) {
                        let timeAhead = opensToday.s - time;
                        if (timeAhead < 1) {
                            // opens soon
                            return {
                                status: 1,
                                openString: "GEÖFFNET",
                                message: "öffnet heute wieder in " + Math.ceil(timeAhead * 60) + " Minuten (" + this.formatHourMin(opensToday.s) + " Uhr)",
                                special: hours.text
                            }
                        } else {
                            // opens today
                            return {
                                status: 1,
                                openString: "GEÖFFNET",
                                message: "öffnet heute wieder ab " + this.formatHourMin(opensToday.s) + " Uhr",
                                special: hours.text
                            }
                        }
                    } else {
                        var today = new Date();
                        today.setTime(now.getTime());
                        today.setHours(0, 0, 0, 0);

                        var date = new Date();
                        date.setTime(today.getTime());
                        var incr = 0;
                        var nextOpenDay = null;
                        do {

                            date.setDate(date.getDate() + 1);
                            incr = incr + 1;

                            nextOpenDay = this.getOpenHoursForDate(date)

                        } while ((nextOpenDay === undefined || nextOpenDay.open.length === 0) && incr <= 30);
                        if (nextOpenDay !== undefined) {
                            let alreadyString = (hours.open.length > 0 ? 'bereits ' : '');

                            let openAt = this.formatHourMin(nextOpenDay.open[0].s);
                            if (incr === 1) {
                                //
                                return {
                                    status: 0,
                                    openString: alreadyString + "GESCHLOSSEN",
                                    message: "morgen wieder geöffnet ab " + openAt + " Uhr",
                                    special: hours.text
                                };
                            } else {
                                let weekday = this.getDayOfWeek(date);
                                if (incr < 7) {
                                    return {
                                        status: 0,
                                        openString: alreadyString + "GESCHLOSSEN",
                                        message: "öffnet wieder kommenden " + this.weekdayString(weekday) + " " + this.formatHourMin(nextOpenDay.open[0].s) + " Uhr",
                                        special: hours.text
                                    };
                                } else {
                                    return {
                                        status: 0,
                                        openString: alreadyString + "GESCHLOSSEN",
                                        message: "öffnet wieder am " + this.formatDateShort(date),
                                        special: hours.text
                                    };
                                }
                            }
                        } else {
                            return "?"
                        }
                    }
                }
            }
        },
        computed: {
            currentStatement() {
                return this.generateStatement(this.now);
            }
        },
        mounted: function () {

            fetch('https://gaertnerei-am-berg.at/_data/openingHours.json', { cache: "no-store"})
                .then(response => response.json())
                .then(data => {
                    this.openingHours = data
                    fetch('https://gaertnerei-am-berg.at/_data/specialHours.json', { cache: "no-store"})
                        .then(response => response.json())
                        .then(data => {
                            this.specialHours = data.map(item => ({
                                ...item,
                                date: new Date(item.date)
                            }));

                            this.specialHours.forEach(s => {
                                console.log(s.text + ": " + s.date)
                                let week = this.getWeekNumber(s.date);
                                let day = this.getDayOfWeek(s.date);
                                s.week = week;
                                s.day = day;
                                s.date.setHours(0, 0, 0, 0);    // need this for specialHours find above, but a bit ugly
                            });
                            console.log(JSON.stringify(this.specialHours))

                            this.interval = setInterval(this.updateCurrentTime, 1000);
                            this.currentOpeningHours = this.findCurrentOpeningHours();
                            let week = this.getWeekNumber(new Date());
                            if (this.currentOpeningHours.toWeek === week) {
                                this.nextWeekOpeningHours = this.findWeeklyOpeningHours(week + 1 > 53 ? 1 : week + 1);
                            }
                        });

                })


        },
    }
</script>

<style scoped>
    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    .current-opening-hours {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .openString {
        font-weight: bold;
        font-size: 22px;
    }

    .openMessage {
        font-weight: bold;
        font-size: 20px;
    }

    .closedMessage {
        font-weight: bold;
        font-size: 16px;
    }

    .open {
        /*color: darkgreen;*/
        color: #A3CB38;
    }

    .closed {
        /*color: red;*/
        color: #C43D23;
    }

    .different-opening-hours-next-week {
        font-size: 26px;
        color: #C43D23;
    }

    .showRemainingWeekButton {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .openingHoursToday {

    }

    .alreadyOver {
        color: gray;
    }

    .day {

    }

    .sunday {
        color: #C43D23;
    }

    .special-today {
        font-size: 26px;
        /*color: #C43D23;*/
    }

    .special-in-week {
        color: #C43D23;
    }

</style>
