import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('../views/Impressum.vue')
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: () => import('../views/Jobs.vue')
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import('../views/Kontakt.vue')
  },
  {
    path: '/oeffnungszeiten',
    name: 'Oeffnungszeiten',
    component: () => import('../views/Oeffnungszeiten.vue')
  }
];

const router = new VueRouter({
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    // If a saved scroll position exists (e.g., when using browser back/forward), restore it
    if (savedPosition) {
      return savedPosition;
    } else {
      // Otherwise, scroll to the top
      return { x: 0, y: 0 };
    }
  }

});

export default router
