<template>
    <div id="app">
        <nav class="navbar navbar-marketing navbar-expand-lg bg-white navbar-light">
            <div class="container">

                <router-link to="/" class="navbar-brand text-dark">Gärtnerei Michael Höller</router-link>
                <button class="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <menu-icon size="1.5x" class="custom-class"></menu-icon>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto mr-lg-5">
                        <li class="nav-item">
                            <router-link to="/" class="nav-link">Home</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/oeffnungszeiten" class="nav-link">Öffnungszeiten</router-link>
                        </li>
                        <li class="nav-item">
                          <router-link to="/jobs" class="nav-link">Jobs</router-link>
                        </li>
                    </ul>
                    <router-link to="/kontakt" class="btn-primary btn rounded-pill px-4 ml-lg-4">KONTAKT<i
                            class="fas fa-arrow-right ml-1"></i></router-link>
                </div>
            </div>
        </nav>
        <router-view/>

        <div id="layoutDefault_footer">
            <footer class="footer pt-5 pb-5 mt-auto bg-white footer-white text-dark">
              <div class="fb-page mb-10"
                   data-href="https://www.facebook.com/GaertnereiAmBergHoeller"
                   data-tabs="timeline"
                   data-width=""
                   data-height=""
                   data-small-header="false"
                   data-adapt-container-width="true"
                   data-hide-cover="false"
                   data-show-facepile="false"
                   data-locale="de_DE"
              >
                <blockquote cite="https://www.facebook.com/GaertnereiAmBergHoeller" class="fb-xfbml-parse-ignore">
                  <a href="https://www.facebook.com/GaertnereiAmBergHoeller">Gärtnerei Höller</a>
                </blockquote>
              </div>
                <div class="container">
                    <div class="row align-items-center">
                      <div class="col-md-6 text-md-right"></div>
                      <div class="col-md-6 text-md-right">
                        <a target="_blank" href="https://www.facebook.com/GaertnereiAmBergHoeller/?locale=de_DE">Facebook</a>
                      </div>
                      <div class="col-md-6 text-md-right"></div>
                      <div class="col-md-6 text-md-right">
                        <a target="_blank" href="https://www.instagram.com/hoeller_gaertnerei_am_berg/?hl=en">Instagram</a>
                      </div>
                        <div class="col-md-6">&copy; Gärtnerei Michael Höller, A-3701 Grossweikersdorf</div>
                        <router-link to="/impressum" class="col-md-6 text-md-right">Impressum</router-link>
                    </div>
                </div>
            </footer>
        </div>

    </div>
</template>

<script>
    import {MenuIcon} from 'vue-feather-icons'

    export default {
        name: 'app',

        components: {
            MenuIcon
        },

        mounted() {
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    #nav {
        padding: 30px;
    }

    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    #nav a.router-link-exact-active {
        color: #42b983;
    }
</style>
